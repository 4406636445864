// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
// import { getPerformance } from 'firebase/performance'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default defineNuxtPlugin(() => {
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//   const firebaseConfig = {
//     apiKey: 'AIzaSyCE_mzu1hCxTOugdr3uZVghXQmE8XYYY2k',
//     authDomain: 'market-app-umico.firebaseapp.com',
//     projectId: 'market-app-umico',
//     storageBucket: 'market-app-umico.appspot.com',
//     messagingSenderId: '466849665972',
//     appId: '1:466849665972:web:88707234daeb9daeb558e1',
//     measurementId: 'G-M9WCF31CQF'
//   }

//   // Initialize Firebase
//   const app = initializeApp(firebaseConfig)
//   getAnalytics(app)
//   getPerformance(app)
})
