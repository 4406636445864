<script setup lang="ts">
import type { VariantProps } from 'class-variance-authority'

type Props = {
  color?: VariantProps<typeof button>['color']
  disabled?: boolean
  loading?: boolean
  outline?: boolean
}

withDefaults(defineProps<Props>(), {
  color: 'primary',
  disabled: false,
  loading: false,
  outline: false
})

const button = cva(
  [
    'border',
    'flex',
    'items-center',
    'justify-center',
    'py-3',
    'px-4',
    'rounded-sm',
    'font-bold',
    'w-full'
  ],
  {
    variants: {
      outline: {
        false: '',
        true: ''
      },
      color: {
        green: ['bg-[#16C67A]', 'border-[#16C67A]', 'text-white'],
        primary: ['bg-[#7c62e3]', 'border-[#7c62e3]', 'text-white'],
        pink: ['bg-[#F81A5D]', 'border-[#F81A5D]', 'text-white'],
        disabled: ['bg-[#D7DBE8]', 'border-[#D7DBE8]', 'text-white', 'cursor-not-allowed']
      }
    },
    compoundVariants: [
      {
        outline: true,
        color: 'primary',
        class: ['bg-transparent', 'text-[#7c62e3]']
      },
      {
        outline: true,
        color: 'pink',
        class: ['bg-transparent', 'text-[#F81A5D]']
      }
    ],
    defaultVariants: {
      outline: false,
      color: 'primary'
    }
  }
)
</script>

<template>
  <button
    :class="cn(button({ color, outline }))"
    :disabled="disabled"
  >
    <UISpinner
      :visible="loading"
      :outline="outline"
    >
      <slot />
    </UISpinner>
  </button>
</template>
