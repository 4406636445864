export const useAuth = () => {
  const { $accessToken, $setAccessToken, $loggedIn } = useNuxtApp()

  const { getIsShowUseAuthLogs } = useConfigStore()

  if (getIsShowUseAuthLogs) {
    console.log('useAuth in composable', $accessToken.value.slice(0, 10), Date.now())
  }

  const { fetchUser } = useUserStore()

  const runtimeConfig = useRuntimeConfig()

  const login = async (phoneNumber: string, code: string) => {
    try {
      const data: ILogin = await useApi('/v2/clients/account/sign-in', {
        baseURL: runtimeConfig.public.customerBaseUrl,
        body: {
          otp_code: code,
          phone: phoneNumber
        },
        method: 'POST'
      })

      await nextTick(() => {
        ($setAccessToken as (name: string) => void)(data.access_token)
        // setRefreshToken(data.refresh_token)
      })

      await fetchUser()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const sendPhoneNumber = async (phoneNumber: string) => {
    try {
      await useApi('/v2/clients/account/sign-in', {
        baseURL: runtimeConfig.public.customerBaseUrl,
        query: { phone: phoneNumber },
        method: 'GET'
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getAccessToken = computed(() => {
    return $accessToken.value
  })

  // console.log('//////////////////////////////////////')
  // console.log('getAccessToken in composable', getAccessToken.value)

  const getLoggedIn: ComputedRef = computed(() => {
    return $loggedIn.value
  })

  return {
    accessToken: getAccessToken,
    loggedIn: getLoggedIn,
    login,
    sendPhoneNumber
  }
}
