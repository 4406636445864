export const useLang = () => {
  const router = useRouter()

  const getLang = computed(() => {
    return router.currentRoute.value.fullPath.includes('/ru/') ? 'ru' : 'az'
  })

  const getLangUrl = computed(() => {
    if (router.currentRoute.value.fullPath.includes('/ru/')) {
      return '/ru'
    }

    return ''
  })

  return {
    getLang,
    getLangUrl
  }
}
