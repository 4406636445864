declare interface IEventReceivePageAnalytics {
  analyticsSearch?: string | null
  itemListIdType?: string | null
  itemListNameSourceType: string
  position: number
  screenBefore: string
}

// types
type Data = IEventReceivePageAnalytics // добавлять сюда типы, если стор будет расширятся через |
type TargetArrayTypes = Data[]

export const usePseudoCacheStore = defineStore('pseudoCache', () => {
  // state
  const productSources = ref<TargetArrayTypes>([])

  // getters
  const firstProductSources = computed(() => {
    if (!productSources.value.length) {
      return undefined
    }

    return productSources.value[productSources.value.length - 1]
  })

  // actions
  const addToTargetArray = (targetArray: TargetArrayTypes, data: Data) => {
    targetArray.push(data)
  }

  const removeLastFromTargetArray = (targetArray: TargetArrayTypes) => {
    targetArray.pop()
  }

  return {
    // state
    productSources,

    // getters
    firstProductSources,

    // actions
    addToTargetArray,
    removeLastFromTargetArray
  }
})
