import type { ComputedRef } from 'vue'
import { computed, watch } from 'vue'
import { defineNuxtPlugin, useCookie, useState } from '#app'

export default defineNuxtPlugin(() => {
  console.log('AUTH plugin init', Date.now())

  const accessToken = useCookie<string>('auth.access_token', {
    default: () => '',
    maxAge: 60 * 60 * 24 * 365,
    watch: true,
    path: '/'
  })

  /// trigger

  console.log('AUTH plugin: accessToken in cookies', accessToken.value.slice(0, 10), Date.now())

  const staticAccesToken = useState<string>('staticAccessToken', () => accessToken.value)

  const getAccessToken = computed((): string => {
    if (accessToken.value.length) return accessToken.value

    if (staticAccesToken.value.length) return staticAccesToken.value

    return 'empty'
  })

  console.log('AUTH plugin: getAccessToken computed', getAccessToken.value.slice(0, 10), Date.now())

  watch(getAccessToken, (value) => {
    console.log('AUTH plugin: watch getAccessToken (when data changed)', value.slice(0, 10), Date.now())
  }
  )

  const setAccessToken = (token: string): void => {
    accessToken.value = token
  }

  const loggedIn: ComputedRef = computed(() => {
    return !!getAccessToken.value
  })

  return {
    provide: {
      accessToken: getAccessToken,
      loggedIn,

      setAccessToken
    }
  }
})
