<script setup lang="ts">
type Props = {
  visible: boolean
  outline?: boolean
}

withDefaults(defineProps<Props>(), {
  outline: false
})

const loader = cva(
  [
    'absolute',
    'animate-spin',
    'aspect-square',
    'border-2',
    'h-6',
    'rounded-full',
    'w-6'
  ],
  {
    variants: {
      outline: {
        false: '',
        true: ''
      }
    },
    compoundVariants: [
      {
        outline: true,
        class: ['border-[#7c62e3]', 'border-b-transparent']
      },
      {
        outline: false,
        class: ['border-white', 'border-b-transparent']
      }
    ],
    defaultVariants: {
      outline: false
    }
  }
)
</script>

<template>
  <div
    v-if="visible"
    class="flex justify-center items-center relative"
  >
    <div :class="cn(loader({ outline }))" />

    <div class="opacity-0">
      <slot />
    </div>
  </div>

  <slot v-else />
</template>
