export type UserCookie = {
  app_version: string
  app_language: string
  ext_id: string
  name: string
  has_premium: boolean
  phone_number: string
}

export const useUserStore = defineStore('user', () => {
  onMounted(() => {
    fetchUser()
    fetchPromoCodesUser()
  })

  // state
  const user = useCookie('user', {
    default: () => null as UserCookie | null,
    maxAge: 60 * 60 * 24 * 365,
    path: '/',
    watch: true
  })

  const runtimeConfig = useRuntimeConfig()
  const isLoadingUser = ref(false)
  const promoCodeUser = ref<PromoCodeUser>({} as PromoCodeUser)
  const userData = ref(null as User | null)

  // actions
  const fetchUser = async () => {
    try {
      isLoadingUser.value = true
      const result: any = await useApi('/v2/clients/account', {
        baseURL: runtimeConfig.public.customerBaseUrl,
        method: 'GET'
      })

      userData.value = result.data?.attributes
    } catch (error) {
      return Promise.reject(error)
    } finally {
      isLoadingUser.value = false
    }
  }

  const fetchPromoCodesUser = async () => {
    try {
      isLoadingUser.value = true
      const result: any = await useApi('/api/v2/promocodes-personal/user', {
        baseURL: runtimeConfig.public.cartApiBaseUrl,
        method: 'GET'
      })

      if (result.data?.[0]) {
        promoCodeUser.value = result.data?.[0]
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      isLoadingUser.value = false
    }
  }

  return {
    user,
    userData,
    isLoadingUser,
    promoCodeUser,
    fetchUser,
    fetchPromoCodesUser
  }
})
