import type { ISeller } from '~/types/cart'
import type { IEventReceivePageAnalytics } from '~/types/mobile'

interface ISendOpenMarketFavoriteScreen {
  screen_name: string
  source: string
}

interface IFavoritesUpdate {
  action: 'add' | 'remove'
  product_id?: number
  title?: string
  brand: string | null
  partner: string | null
  discount_percentage?: number
  category_id?: string
  price?: number
  original_price?: number
  discount_value?: number | string
  local_currency: 'AZN'
  language: string
  product_ids?: string | null
  os: string
  page_type: string
  button_type: 'add' | 'remove'
  item_category?: string
}

interface IAddToWishlist {
  items: IAddToWishlistItem[]
  items_name: string
  value: number
  items_price: number
  items_category: string
  currency: 'AZN'
  source: string
}

interface IAddToWishlistItem {
  item_id: number
  item_name: string
  quantity: number
  price: number
  item_brand: string
  item_list_name: string
  index: number
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
}

interface IChooseOfferItem {
  item_id: number
  item_name: string
  quantity: number
  price: number
  item_brand: string
  item_list_name: string
  item_list_id: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  index: number
}

interface IEventAddToCart {
  value: number
  currency: 'AZN'
  source: string
  items: IChooseOfferItem[]
}

interface ICartUpdate {
  page_type: string
  action: 'add' | 'remove'
  button_type: 'plus' | 'minus' | 'add'
  product_id: number
  title: string
  brand: string
  partner: string
  discount_percentage: number
  category_id: number
  price: number
  original_price: number
  discount_value: number
  local_currency: string
  language: string
  product_ids: string
  category_1?: number | null
  category_ids: string | string[] | number | number[]
  total_price: number
  total_quantity: number
  product_list: Array<{
    quantity: number
    product_id: number
  }> | string
}

interface IProductDetailsItem {
  item_id: number
  item_name: string
  quantity: 1
  price: number
  item_brand: string
  item_list_name: string
  item_list_id: number | string
  index: number
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
}

interface IProductDetails {
  value: number
  currency: 'AZN'
  source: string
  items_price: number
  availability: string
  items: IProductDetailsItem[]
}

interface IViewItem {
  value: number
  currency: 'AZN'
  source: string
  availability: string
  items: IViewItemItem[]
}

interface IViewItemV2 {
  product_id: number
  title: string
  brand: string
  categories_path: string
  category_1?: string
  category_2?: string
  category_3?: string
  category_4?: string
  category_5?: string
  category_ids: string
  category_id: number
  price: number
  discount_percentage: number
  discount_value: number | string
  original_price: number
  language: string
  item_list_name: string
}

interface IViewItemItem {
  item_id: number
  item_name: string
  quantity: number
  price: number
  item_brand: string
  item_list_name: string
  index: number
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
}

interface IViewItemABTest {
  platform_type: string
  error_triggered: 0 | 1
}

interface IBtAddToCartPopupClick {
  button: string
  product_id: number
}

interface IEventCheckTime {
  point: 'start web app' | 'data was fetched to render product list'
}

interface IEventOpenComparisons {
  item_id: number
  item_name: string
  quantity: number
  item_brand: string
  price: number
  items_name: string
  value: number
  items_price: number
  items_category: string
  currency: 'AZN'
  items_list_name: string
  source: string
}

export const useAnalyticStore = defineStore('analytic', () => {
  const { eventAnalytics, eventBloomrichAnalytics } = useMobileEvents()
  const router = useRouter()

  // State
  const receiveAnaliticsData = ref<IEventReceivePageAnalytics>(
    {
      position: 0,
      itemListNameSourceType: '',
      screenBefore: ''
    }
  )

  // Helpers

  const getCategoriesForAnalytics = (categories: string) => {
    const result = categories.split('|')
      .map(category => category.trim().split('-').reverse().join('_')
      ).reverse()
    const resObj: Record<string, string> = {}
    result.forEach((item, index) => {
      resObj[`item_category${index > 0 ? index + 1 : ''}`] = item
    })
    return resObj
  }

  const getCategoriesIdsForAnalytics = (categories: string) => {
    return categories.split('|')
      .map(category => +category.trim().split('-')[0])
  }

  const getCategoriesNamesForAnalytics = (categories: string) => {
    const resObj: Record<string, string> = {}
    categories.split('|')
      .map((category, index) => {
        resObj[`category_${index + 1}`] = category.trim().split('-')[1]
      }
      )
    return resObj
  }

  const getDiscountValueForAnalytics = (oldPrice: number, newPrice: number) => {
    return oldPrice ? Math.round((oldPrice - newPrice) / oldPrice * 100) : 0
  }

  const getItemsInCart = (sellers: ISeller[]) => {
    const res: { product_id: number, quantity: number }[] = []

    sellers.map((seller) => {
      seller.line_items.map((item) => {
        res.push({
          product_id: item.product_id,
          quantity: item.quantity
        })
      })
    })
    return res
  }

  const getTotalPriceInCart = (sellers: ISeller[]) => {
    let totalPrice = 0
    sellers.map((seller) => {
      seller.line_items.map((item) => {
        totalPrice += item.quantity * item.price
      })
    })
    return totalPrice
  }

  const getTotalQuantityInCart = (sellers: ISeller[]) => {
    let totalQuantity = 0
    sellers.map((seller) => {
      seller.line_items.map((item) => {
        totalQuantity += item.quantity
      })
    })
    return totalQuantity
  }

  const getScreenBeforeName = computed(() => {
    if (router.currentRoute.value.path.includes('/ru/')) {
      return router.currentRoute.value.path.slice(4)
    }

    return router.currentRoute.value.path.slice(1)
  })

  // Analytics events

  // Если есть ивент, но нету params юзаем ЭТОТ метод
  const analyticsWithoutParams = (eventName: string) => {
    eventAnalytics(eventName, {})
  }

  const eventSendOpenMarketFavoriteScreen = (
    params: ISendOpenMarketFavoriteScreen
  ) => {
    eventAnalytics('scr_favorite_open', params)
  }

  const eventCheckTime = (params: IEventCheckTime) => {
    eventAnalytics(`favorites`, {
      ...params,
      time: Date.now()
    })
  }

  /// favorites
  const eventAddToWishlist = (params: IAddToWishlist) => {
    eventAnalytics('add_to_wishlist', params)
  }

  const eventUpdateFavoritesAnalytics = (params: IFavoritesUpdate) => {
    eventAnalytics(`favorites_update`, params)
  }
  ///

  const eventBtAddToCartPopupClick = (params: IBtAddToCartPopupClick) => {
    eventAnalytics(`bt_add_to_cart_popup_click`, params)
  }

  const eventAddToCart = (params: IEventAddToCart) => {
    eventAnalytics('add_to_cart', params)
  }

  const eventCartUpdate = (params: ICartUpdate) => {
    eventAnalytics(`cart_update`, params)
  }

  const eventProductDetails = (params: IProductDetails) => {
    eventAnalytics('select_item', params)
  }

  const eventViewItem = (params: IViewItem) => {
    eventAnalytics('view_item', params)
  }

  const eventViewItemABTest = (params: IViewItemABTest) => {
    eventAnalytics('view_item_AB_test', params)
  }

  const eventViewItemBloomrich = (params: IViewItemV2) => {
    eventBloomrichAnalytics('view_item', params)
  }

  const eventViewAllOffers = (params: { count: number }) => {
    eventAnalytics('view_all_offers', params)
  }

  const eventBannerCreditLine = (params: { source: string }) => {
    eventAnalytics('banner_credit_line_click', params)
  }

  const eventOpenComparisons = (params: IEventOpenComparisons) => {
    eventAnalytics('add_to_comparison', params)
  }

  return {
    // State
    receiveAnaliticsData,

    // Methods
    eventSendOpenMarketFavoriteScreen,
    eventUpdateFavoritesAnalytics,
    eventAddToWishlist,
    eventBtAddToCartPopupClick,
    eventCartUpdate,
    eventProductDetails,
    eventViewItem,
    eventViewItemABTest,
    eventAddToCart,
    eventCheckTime,
    eventViewAllOffers,
    analyticsWithoutParams,
    eventBannerCreditLine,
    eventOpenComparisons,

    getCategoriesForAnalytics,
    getCategoriesIdsForAnalytics,
    getDiscountValueForAnalytics,
    getItemsInCart,
    getCategoriesNamesForAnalytics,
    getTotalPriceInCart,
    getTotalQuantityInCart,
    getScreenBeforeName,

    // Bloomrich
    eventViewItemBloomrich
  }
})
