import type { NitroFetchRequest } from 'nitropack'
import * as Sentry from '@sentry/nuxt'

export async function useApi<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest
>(
  request: Parameters<typeof $fetch<T, R>>[0],
  options?: Partial<Parameters<typeof $fetch<T, R>>[1]>
) {
  const { accessToken, loggedIn } = useAuth()
  const { getIsShowUseApiLogs } = useConfigStore()
  // console.log('accesToken in api.ts', accessToken.value)
  // console.log('loggedIn in api.ts', loggedIn.value)

  if (getIsShowUseApiLogs) {
    console.log ('useApi composable', accessToken.value.slice(0, 10), Date.now())
  }

  const device = useDevice()
  const { getLang } = useLang()

  Sentry.addBreadcrumb({
    category: 'apiCall',
    message: 'message',
    level: 'info',
    type: 'API',
    data: {
      request: String(request)
    }
  })

  if (accessToken.value === 'empty') {
    throw showError({
      fatal: true,
      statusCode: 500,
      message: 'Acces token is empty'
    })
  }

  const response = await $fetch<T, R>(request, {
    ...options,
    headers: {
      ...(accessToken.value && { Authorization: `Bearer ${accessToken.value}` }),
      Accept: 'application/json',
      'Accept-Language': getLang.value,
      'Content-Language': getLang.value,
      HTTP_ACCEPT_LANGUAGE: getLang.value,
      HTTP_CONTENT_LANGUAGE: getLang.value,
      ...options?.headers
    },
    onRequest: (request) => {
      if (getIsShowUseApiLogs) {
        console.log ('useApi onRequest', accessToken.value.slice(0, 10), Date.now())
      }
      Sentry.addBreadcrumb({
        category: 'apiRequest',
        message: 'message',
        level: 'info',
        type: 'API',
        data: {
          request: String(request)
        }
      })
    },
    onRequestError: (error) => {
      if (getIsShowUseApiLogs) {
        console.log ('useApi onRequestError', accessToken.value.slice(0, 10), Date.now())
      }
      Sentry.withScope(() => {
        Sentry.setTag('api.url', String(request))
        Sentry.captureException(
          new Error(`apiError ${error?.message}, statusApiError ${error?.status}`)
        )
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'message',
        level: 'info',
        data: {
          isAuth: loggedIn.value,
          isDesktop: device.isDesktop,
          authToken: accessToken.value.slice(0, 15)
        }
      })
    },
    onResponse: (response) => {
      if (getIsShowUseApiLogs) {
        console.log ('useApi onResponse', accessToken.value.slice(0, 10), Date.now())
      }
      Sentry.addBreadcrumb({
        category: 'apiResponse',
        message: 'message',
        level: 'info',
        type: 'API',
        data: {
          request: String(request),
          response
        }
      })
    },
    onResponseError: (error) => {
      if (getIsShowUseApiLogs) {
        console.log ('useApi onResponseError', accessToken.value.slice(0, 10), Date.now())
      }
      Sentry.withScope(() => {
        Sentry.setTag('api.url', String(request))
        Sentry.captureException(
          new Error(`apiError ${error?.message}, statusApiError ${error?.status}`)
        )
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'message',
        level: 'info',
        data: {
          isAuth: loggedIn.value,
          isDesktop: device.isDesktop,
          authToken: accessToken.value.slice(0, 15)
        }
      })
    }
  })

  return response
}
