import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

const MAX_LENGTH_STRING = 90

export { cva } from 'class-variance-authority'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const formatCurrency = (value = 0, options = {}) => {
  const defaults = {
    symbol: '₼',
    symbolPosition: 'back',
    useSpacing: true,
    thousandsSeparator: ' ',
    separator: '.',
    fractionCount: 2,
    fractionSeparator: '.',
    avoidEmptyDecimals: false,
    emptyDecimalsString: ''
  }
  const config = { ...defaults, ...options }
  let formattedValue = Number(value)
    .toFixed(config.fractionCount)
    .replace('.', config.fractionSeparator)

  if (config.avoidEmptyDecimals) {
    const regex = new RegExp(`(\\${config.fractionSeparator}[0]+)$`)
    formattedValue = formattedValue.replace(
      regex,
      config.emptyDecimalsString ? config.emptyDecimalsString : ''
    )
  }
  if (config.useSpacing) {
    formattedValue = formattedValue.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      config.thousandsSeparator
    )
  }
  return config.symbolPosition === 'front'
    ? config.symbol + (config.useSpacing ? ' ' : '') + formattedValue
    : formattedValue + (config.useSpacing ? ' ' : '') + config.symbol
}

export const calculationInstallment = (amount: number, months: number) =>
  (Math.ceil((amount / months) * 100) / 100).toFixed(2)

export const deepRemoveNull = (obj: any) => {
  if (obj.items?.length) {
    deepRemoveNull(obj.items[0])
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) {
      delete obj[key]
    } else {
      if (
        typeof obj[key] === 'number' &&
        !key.includes('price') &&
        key !== 'value'
      ) {
        obj[key] = parseInt(obj[key])
      }
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim().substr(0, MAX_LENGTH_STRING)
      }
    }
  })
}
