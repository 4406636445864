import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin(async () => {
  console.log('SENTRY plugin init', Date.now())

  const { $accessToken } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()

  if (!runtimeConfig.public.sentry.dsn) {
    return
  }

  console.log('SENRTY plugin accesToken get', $accessToken.value.slice(0, 10), Date.now())

  const router = useRouter()
  const userStore = useUserStore()
  const { user, userData } = storeToRefs(userStore)

  Sentry.init({
    dsn: runtimeConfig.public.sentry.dsn,
    environment: runtimeConfig.public.sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router, enableInp: true })
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 1.0, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', `https://${window.location.host}`],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0 // Change in prod if necessary
  })

  const updateSentryUser = async () => {
    if (!user) {
      return
    }

    console.log('SENTRY user update', $accessToken.value.slice(0, 10), Date.now())
    // await userStore.fetchUser()

    Sentry.setUser({ id: user.value?.ext_id })

    Sentry.setTag('user.app_version', user.value?.app_version)
    Sentry.setTag('user.app_language', user.value?.app_language)
    Sentry.setTag(
      'user.phone_number',
      user.value?.phone_number || userData.value?.phone
    )
    Sentry.setTag('user.ext_id', user.value?.ext_id)
    Sentry.setTag('user.name', user.value?.name)
    Sentry.setTag('user.has_premium', user.value?.has_premium)
  }

  import('@sentry/nuxt').then((lazyLoadedSentry) => {
    Sentry.addIntegration(lazyLoadedSentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }))
  })

  watch(
    () => user,
    () => {
      updateSentryUser()
    },
    {
      deep: true,
      immediate: true
    }
  )
})
