import { default as indexsqNzSSYNAUMeta } from "/app/pages/auth/authentication/index.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as indexKL1s9FFNEvMeta } from "/app/pages/favorites/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93QIRuLOTNJ6Meta } from "/app/pages/product/[id].vue?macro=true";
import { default as BadgeCredithHGyXAL3jkMeta } from "/app/pages/product/~components/BadgeCredit.vue?macro=true";
import { default as BadgeInstallment9HJwBWcdIlMeta } from "/app/pages/product/~components/BadgeInstallment.vue?macro=true";
import { default as ButtonVideoReviewskT1XlVGNJQMeta } from "/app/pages/product/~components/ButtonVideoReviews.vue?macro=true";
import { default as CompanyInfoChcl5tIHUIMeta } from "/app/pages/product/~components/CompanyInfo.vue?macro=true";
import { default as ProductAlternativeProductshwiElWK31zMeta } from "/app/pages/product/~components/ProductAlternativeProducts.vue?macro=true";
import { default as ProductDeliveryMethodsus1Qyt8BArMeta } from "/app/pages/product/~components/ProductDeliveryMethods.vue?macro=true";
import { default as ProductDescriptionetfzd11tVbMeta } from "/app/pages/product/~components/ProductDescription.vue?macro=true";
import { default as ProductFavoriteButton6ueCTneOwjMeta } from "/app/pages/product/~components/ProductFavoriteButton.vue?macro=true";
import { default as CompaniesZZca4smOeEMeta } from "/app/pages/product/~components/ProductInfo/Companies.vue?macro=true";
import { default as MainHfo8nyAbUoMeta } from "/app/pages/product/~components/ProductInfo/Main.vue?macro=true";
import { default as ReviewsWrOeswMiNDMeta } from "/app/pages/product/~components/ProductInfo/Reviews.vue?macro=true";
import { default as SkeletonDjpZSb0PX3Meta } from "/app/pages/product/~components/ProductInfo/Skeleton.vue?macro=true";
import { default as StatUiDga5EXxHMeta } from "/app/pages/product/~components/ProductInfo/Stat.vue?macro=true";
import { default as VideoYup2ADuXYiMeta } from "/app/pages/product/~components/ProductInfo/Video.vue?macro=true";
import { default as Cashbackx7IHZRoMBFMeta } from "/app/pages/product/~components/ProductLabels/Cashback.vue?macro=true";
import { default as CustomsxhcVyAtsAAMeta } from "/app/pages/product/~components/ProductLabels/Customs.vue?macro=true";
import { default as DeliveryuOhGXrZHZiMeta } from "/app/pages/product/~components/ProductLabels/Delivery.vue?macro=true";
import { default as DeliveryTimeAi0FT174XAMeta } from "/app/pages/product/~components/ProductLabels/DeliveryTime.vue?macro=true";
import { default as IndexYblwfn2HOhMeta } from "/app/pages/product/~components/ProductLabels/Index.vue?macro=true";
import { default as LayaltyyqwgbNaeSsMeta } from "/app/pages/product/~components/ProductLabels/Layalty.vue?macro=true";
import { default as PreorderHh4AMyb38UMeta } from "/app/pages/product/~components/ProductLabels/Preorder.vue?macro=true";
import { default as ProductPaymentMethodsr61rqkpf6oMeta } from "/app/pages/product/~components/ProductPaymentMethods.vue?macro=true";
import { default as ProductSwiperPRSPXfKaqnMeta } from "/app/pages/product/~components/ProductSwiper.vue?macro=true";
import { default as ProductTimerEmvkDeldV8Meta } from "/app/pages/product/~components/ProductTimer.vue?macro=true";
import { default as indexgFHc0sx9NaMeta } from "/app/pages/product/~components/ProductVariants/index.vue?macro=true";
import { default as Modal377leEIU7RMeta } from "/app/pages/product/~components/ProductVariants/Modal.vue?macro=true";
import { default as SelectVH4dAmpirJMeta } from "/app/pages/product/~components/ProductVariants/Select.vue?macro=true";
import { default as QuantityDiscount70WJJQDFZwMeta } from "/app/pages/product/~components/QuantityDiscount.vue?macro=true";
import { default as ReviewsVfB7A1ZTx1Meta } from "/app/pages/product/~components/Reviews.vue?macro=true";
import { default as index0B5EdHQ2WtMeta } from "/app/pages/product/~components/SellersList/index.vue?macro=true";
import { default as ListItemxJlLwDeSx8Meta } from "/app/pages/product/~components/SellersList/ListItem.vue?macro=true";
import { default as SkeletonX0eYJhHBxXMeta } from "/app/pages/product/~components/Skeleton.vue?macro=true";
export default [
  {
    name: "auth-authentication___az",
    path: "/auth/authentication",
    component: () => import("/app/pages/auth/authentication/index.vue")
  },
  {
    name: "auth-authentication___ru",
    path: "/ru/auth/authentication",
    component: () => import("/app/pages/auth/authentication/index.vue")
  },
  {
    name: "cart___az",
    path: "/cart",
    meta: indexAacQV8oPDMMeta || {},
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "cart___ru",
    path: "/ru/cart",
    meta: indexAacQV8oPDMMeta || {},
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "favorites___az",
    path: "/favorites",
    meta: indexKL1s9FFNEvMeta || {},
    component: () => import("/app/pages/favorites/index.vue")
  },
  {
    name: "favorites___ru",
    path: "/ru/favorites",
    meta: indexKL1s9FFNEvMeta || {},
    component: () => import("/app/pages/favorites/index.vue")
  },
  {
    name: "index___az",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "product-id___az",
    path: "/product/:id()",
    meta: _91id_93QIRuLOTNJ6Meta || {},
    component: () => import("/app/pages/product/[id].vue")
  },
  {
    name: "product-id___ru",
    path: "/ru/product/:id()",
    meta: _91id_93QIRuLOTNJ6Meta || {},
    component: () => import("/app/pages/product/[id].vue")
  },
  {
    name: "product-~components-BadgeCredit___az",
    path: "/product/~components/BadgeCredit",
    component: () => import("/app/pages/product/~components/BadgeCredit.vue")
  },
  {
    name: "product-~components-BadgeCredit___ru",
    path: "/ru/product/~components/BadgeCredit",
    component: () => import("/app/pages/product/~components/BadgeCredit.vue")
  },
  {
    name: "product-~components-BadgeInstallment___az",
    path: "/product/~components/BadgeInstallment",
    component: () => import("/app/pages/product/~components/BadgeInstallment.vue")
  },
  {
    name: "product-~components-BadgeInstallment___ru",
    path: "/ru/product/~components/BadgeInstallment",
    component: () => import("/app/pages/product/~components/BadgeInstallment.vue")
  },
  {
    name: "product-~components-ButtonVideoReviews___az",
    path: "/product/~components/ButtonVideoReviews",
    component: () => import("/app/pages/product/~components/ButtonVideoReviews.vue")
  },
  {
    name: "product-~components-ButtonVideoReviews___ru",
    path: "/ru/product/~components/ButtonVideoReviews",
    component: () => import("/app/pages/product/~components/ButtonVideoReviews.vue")
  },
  {
    name: "product-~components-CompanyInfo___az",
    path: "/product/~components/CompanyInfo",
    component: () => import("/app/pages/product/~components/CompanyInfo.vue")
  },
  {
    name: "product-~components-CompanyInfo___ru",
    path: "/ru/product/~components/CompanyInfo",
    component: () => import("/app/pages/product/~components/CompanyInfo.vue")
  },
  {
    name: "product-~components-ProductAlternativeProducts___az",
    path: "/product/~components/ProductAlternativeProducts",
    component: () => import("/app/pages/product/~components/ProductAlternativeProducts.vue")
  },
  {
    name: "product-~components-ProductAlternativeProducts___ru",
    path: "/ru/product/~components/ProductAlternativeProducts",
    component: () => import("/app/pages/product/~components/ProductAlternativeProducts.vue")
  },
  {
    name: "product-~components-ProductDeliveryMethods___az",
    path: "/product/~components/ProductDeliveryMethods",
    component: () => import("/app/pages/product/~components/ProductDeliveryMethods.vue")
  },
  {
    name: "product-~components-ProductDeliveryMethods___ru",
    path: "/ru/product/~components/ProductDeliveryMethods",
    component: () => import("/app/pages/product/~components/ProductDeliveryMethods.vue")
  },
  {
    name: "product-~components-ProductDescription___az",
    path: "/product/~components/ProductDescription",
    component: () => import("/app/pages/product/~components/ProductDescription.vue")
  },
  {
    name: "product-~components-ProductDescription___ru",
    path: "/ru/product/~components/ProductDescription",
    component: () => import("/app/pages/product/~components/ProductDescription.vue")
  },
  {
    name: "product-~components-ProductFavoriteButton___az",
    path: "/product/~components/ProductFavoriteButton",
    component: () => import("/app/pages/product/~components/ProductFavoriteButton.vue")
  },
  {
    name: "product-~components-ProductFavoriteButton___ru",
    path: "/ru/product/~components/ProductFavoriteButton",
    component: () => import("/app/pages/product/~components/ProductFavoriteButton.vue")
  },
  {
    name: "product-~components-ProductInfo-Companies___az",
    path: "/product/~components/ProductInfo/Companies",
    component: () => import("/app/pages/product/~components/ProductInfo/Companies.vue")
  },
  {
    name: "product-~components-ProductInfo-Companies___ru",
    path: "/ru/product/~components/ProductInfo/Companies",
    component: () => import("/app/pages/product/~components/ProductInfo/Companies.vue")
  },
  {
    name: "product-~components-ProductInfo-Main___az",
    path: "/product/~components/ProductInfo/Main",
    component: () => import("/app/pages/product/~components/ProductInfo/Main.vue")
  },
  {
    name: "product-~components-ProductInfo-Main___ru",
    path: "/ru/product/~components/ProductInfo/Main",
    component: () => import("/app/pages/product/~components/ProductInfo/Main.vue")
  },
  {
    name: "product-~components-ProductInfo-Reviews___az",
    path: "/product/~components/ProductInfo/Reviews",
    component: () => import("/app/pages/product/~components/ProductInfo/Reviews.vue")
  },
  {
    name: "product-~components-ProductInfo-Reviews___ru",
    path: "/ru/product/~components/ProductInfo/Reviews",
    component: () => import("/app/pages/product/~components/ProductInfo/Reviews.vue")
  },
  {
    name: "product-~components-ProductInfo-Skeleton___az",
    path: "/product/~components/ProductInfo/Skeleton",
    component: () => import("/app/pages/product/~components/ProductInfo/Skeleton.vue")
  },
  {
    name: "product-~components-ProductInfo-Skeleton___ru",
    path: "/ru/product/~components/ProductInfo/Skeleton",
    component: () => import("/app/pages/product/~components/ProductInfo/Skeleton.vue")
  },
  {
    name: "product-~components-ProductInfo-Stat___az",
    path: "/product/~components/ProductInfo/Stat",
    component: () => import("/app/pages/product/~components/ProductInfo/Stat.vue")
  },
  {
    name: "product-~components-ProductInfo-Stat___ru",
    path: "/ru/product/~components/ProductInfo/Stat",
    component: () => import("/app/pages/product/~components/ProductInfo/Stat.vue")
  },
  {
    name: "product-~components-ProductInfo-Video___az",
    path: "/product/~components/ProductInfo/Video",
    component: () => import("/app/pages/product/~components/ProductInfo/Video.vue")
  },
  {
    name: "product-~components-ProductInfo-Video___ru",
    path: "/ru/product/~components/ProductInfo/Video",
    component: () => import("/app/pages/product/~components/ProductInfo/Video.vue")
  },
  {
    name: "product-~components-ProductLabels-Cashback___az",
    path: "/product/~components/ProductLabels/Cashback",
    component: () => import("/app/pages/product/~components/ProductLabels/Cashback.vue")
  },
  {
    name: "product-~components-ProductLabels-Cashback___ru",
    path: "/ru/product/~components/ProductLabels/Cashback",
    component: () => import("/app/pages/product/~components/ProductLabels/Cashback.vue")
  },
  {
    name: "product-~components-ProductLabels-Customs___az",
    path: "/product/~components/ProductLabels/Customs",
    component: () => import("/app/pages/product/~components/ProductLabels/Customs.vue")
  },
  {
    name: "product-~components-ProductLabels-Customs___ru",
    path: "/ru/product/~components/ProductLabels/Customs",
    component: () => import("/app/pages/product/~components/ProductLabels/Customs.vue")
  },
  {
    name: "product-~components-ProductLabels-Delivery___az",
    path: "/product/~components/ProductLabels/Delivery",
    component: () => import("/app/pages/product/~components/ProductLabels/Delivery.vue")
  },
  {
    name: "product-~components-ProductLabels-Delivery___ru",
    path: "/ru/product/~components/ProductLabels/Delivery",
    component: () => import("/app/pages/product/~components/ProductLabels/Delivery.vue")
  },
  {
    name: "product-~components-ProductLabels-DeliveryTime___az",
    path: "/product/~components/ProductLabels/DeliveryTime",
    component: () => import("/app/pages/product/~components/ProductLabels/DeliveryTime.vue")
  },
  {
    name: "product-~components-ProductLabels-DeliveryTime___ru",
    path: "/ru/product/~components/ProductLabels/DeliveryTime",
    component: () => import("/app/pages/product/~components/ProductLabels/DeliveryTime.vue")
  },
  {
    name: "product-~components-ProductLabels-Index___az",
    path: "/product/~components/ProductLabels/Index",
    component: () => import("/app/pages/product/~components/ProductLabels/Index.vue")
  },
  {
    name: "product-~components-ProductLabels-Index___ru",
    path: "/ru/product/~components/ProductLabels/Index",
    component: () => import("/app/pages/product/~components/ProductLabels/Index.vue")
  },
  {
    name: "product-~components-ProductLabels-Layalty___az",
    path: "/product/~components/ProductLabels/Layalty",
    component: () => import("/app/pages/product/~components/ProductLabels/Layalty.vue")
  },
  {
    name: "product-~components-ProductLabels-Layalty___ru",
    path: "/ru/product/~components/ProductLabels/Layalty",
    component: () => import("/app/pages/product/~components/ProductLabels/Layalty.vue")
  },
  {
    name: "product-~components-ProductLabels-Preorder___az",
    path: "/product/~components/ProductLabels/Preorder",
    component: () => import("/app/pages/product/~components/ProductLabels/Preorder.vue")
  },
  {
    name: "product-~components-ProductLabels-Preorder___ru",
    path: "/ru/product/~components/ProductLabels/Preorder",
    component: () => import("/app/pages/product/~components/ProductLabels/Preorder.vue")
  },
  {
    name: "product-~components-ProductPaymentMethods___az",
    path: "/product/~components/ProductPaymentMethods",
    component: () => import("/app/pages/product/~components/ProductPaymentMethods.vue")
  },
  {
    name: "product-~components-ProductPaymentMethods___ru",
    path: "/ru/product/~components/ProductPaymentMethods",
    component: () => import("/app/pages/product/~components/ProductPaymentMethods.vue")
  },
  {
    name: "product-~components-ProductSwiper___az",
    path: "/product/~components/ProductSwiper",
    component: () => import("/app/pages/product/~components/ProductSwiper.vue")
  },
  {
    name: "product-~components-ProductSwiper___ru",
    path: "/ru/product/~components/ProductSwiper",
    component: () => import("/app/pages/product/~components/ProductSwiper.vue")
  },
  {
    name: "product-~components-ProductTimer___az",
    path: "/product/~components/ProductTimer",
    component: () => import("/app/pages/product/~components/ProductTimer.vue")
  },
  {
    name: "product-~components-ProductTimer___ru",
    path: "/ru/product/~components/ProductTimer",
    component: () => import("/app/pages/product/~components/ProductTimer.vue")
  },
  {
    name: "product-~components-ProductVariants___az",
    path: "/product/~components/ProductVariants",
    component: () => import("/app/pages/product/~components/ProductVariants/index.vue")
  },
  {
    name: "product-~components-ProductVariants___ru",
    path: "/ru/product/~components/ProductVariants",
    component: () => import("/app/pages/product/~components/ProductVariants/index.vue")
  },
  {
    name: "product-~components-ProductVariants-Modal___az",
    path: "/product/~components/ProductVariants/Modal",
    component: () => import("/app/pages/product/~components/ProductVariants/Modal.vue")
  },
  {
    name: "product-~components-ProductVariants-Modal___ru",
    path: "/ru/product/~components/ProductVariants/Modal",
    component: () => import("/app/pages/product/~components/ProductVariants/Modal.vue")
  },
  {
    name: "product-~components-ProductVariants-Select___az",
    path: "/product/~components/ProductVariants/Select",
    component: () => import("/app/pages/product/~components/ProductVariants/Select.vue")
  },
  {
    name: "product-~components-ProductVariants-Select___ru",
    path: "/ru/product/~components/ProductVariants/Select",
    component: () => import("/app/pages/product/~components/ProductVariants/Select.vue")
  },
  {
    name: "product-~components-QuantityDiscount___az",
    path: "/product/~components/QuantityDiscount",
    component: () => import("/app/pages/product/~components/QuantityDiscount.vue")
  },
  {
    name: "product-~components-QuantityDiscount___ru",
    path: "/ru/product/~components/QuantityDiscount",
    component: () => import("/app/pages/product/~components/QuantityDiscount.vue")
  },
  {
    name: "product-~components-Reviews___az",
    path: "/product/~components/Reviews",
    component: () => import("/app/pages/product/~components/Reviews.vue")
  },
  {
    name: "product-~components-Reviews___ru",
    path: "/ru/product/~components/Reviews",
    component: () => import("/app/pages/product/~components/Reviews.vue")
  },
  {
    name: "product-~components-SellersList___az",
    path: "/product/~components/SellersList",
    component: () => import("/app/pages/product/~components/SellersList/index.vue")
  },
  {
    name: "product-~components-SellersList___ru",
    path: "/ru/product/~components/SellersList",
    component: () => import("/app/pages/product/~components/SellersList/index.vue")
  },
  {
    name: "product-~components-SellersList-ListItem___az",
    path: "/product/~components/SellersList/ListItem",
    component: () => import("/app/pages/product/~components/SellersList/ListItem.vue")
  },
  {
    name: "product-~components-SellersList-ListItem___ru",
    path: "/ru/product/~components/SellersList/ListItem",
    component: () => import("/app/pages/product/~components/SellersList/ListItem.vue")
  },
  {
    name: "product-~components-Skeleton___az",
    path: "/product/~components/Skeleton",
    component: () => import("/app/pages/product/~components/Skeleton.vue")
  },
  {
    name: "product-~components-Skeleton___ru",
    path: "/ru/product/~components/Skeleton",
    component: () => import("/app/pages/product/~components/Skeleton.vue")
  }
]