import revive_payload_client_BoRmWzLdeJ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SxhRlHrFdN from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6w8AmlfeFZ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_maiug1IXFz from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OiMWZuzjio from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VbNoX24YI1 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GJXiYbRBnE from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vp3d7Q6aSG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_8jH7E5cCS0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__7fosmodfomxgebgw37iaibxbcq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SOrYddXlp7 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_J5pf4Lw7RK from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.3_terser@5.34._r4egv2tlbpbrzsnhakwk3p7l2q/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_uPHqZFAtyk from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_x1xJWuQ30p from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.9_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_global_yn9q99QC5S from "/app/plugins/auth.global.ts";
import firebase_client_zXNDa0wxFH from "/app/plugins/firebase.client.ts";
import lazy_load_client_YS6QtUbeSl from "/app/plugins/lazy-load.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_BoRmWzLdeJ,
  unhead_SxhRlHrFdN,
  router_6w8AmlfeFZ,
  payload_client_maiug1IXFz,
  navigation_repaint_client_OiMWZuzjio,
  check_outdated_build_client_VbNoX24YI1,
  chunk_reload_client_GJXiYbRBnE,
  plugin_vue3_Vp3d7Q6aSG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8jH7E5cCS0,
  plugin_SOrYddXlp7,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_J5pf4Lw7RK,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_uPHqZFAtyk,
  i18n_x1xJWuQ30p,
  auth_global_yn9q99QC5S,
  firebase_client_zXNDa0wxFH,
  lazy_load_client_YS6QtUbeSl,
  sentry_client_shVUlIjFLk
]