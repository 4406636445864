export const useJsonHelpers = () => {
  type JsonValueType = string | number | boolean | null
  type JsonValue = JsonValueType | JsonValueType[] | SizeChart

  const snakeToCamel = (str: string): string => {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
  }

  const camelToSnake = (str: string): string => {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  }

  const convertKeysToCamelCase = (obj: Record<string, any>): Record<string, any> => {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item))
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = snakeToCamel(key)
        acc[camelCaseKey] = convertKeysToCamelCase(obj[key])
        return acc
      }, {} as Record<string, any>)
    }
    return obj
  }

  const returnKeys = (key: string, value: JsonValue): Record<string, JsonValue> => {
    return {
      [snakeToCamel(key)]: value,
      [camelToSnake(key)]: value
    }
  }

  return {
    convertKeysToCamelCase,
    returnKeys
  }
}
