import * as Sentry from '@sentry/nuxt'
import { deepRemoveNull } from '~/utils'
import type { EventSendMobileScreenNameType, IEventReceivePageAnalytics } from '~/types/mobile'

export const useMobileEvents = () => {
  type EventsDevice =
    | 'eventPageLoaded' //
    | 'eventOpenVideoList' //
    | 'eventOpenReviews' // +
    | 'eventUpdateCart' // +
    | 'eventUpdateProductQuantity'
    | 'eventDeleteProductFromCart'
    | 'eventAddProductToFavourite' // +
    | 'eventDeleteProductFromFavorites'
    | 'eventOpenProductImages' // +
    | 'eventOpenCart' //
    | 'eventOpenSearchByCategory' // +
    | 'eventAddToComparisons' // +
    | 'eventOpenComparisons' // +
    | 'eventOpenProductDetails' // +
    | 'eventOpenCatalog' //
    | 'eventSendToAnalyticEvent'
    | 'eventSendToBloomreachEvent'
    | 'SendToAnalytic'
    | 'eventOpenAllMegaDiscounts' //
    | 'eventOpenAllSimilar' //
    | 'eventGlobalEventsReady' //
    | 'eventBuyOnCredit' //
    | 'eventOpenSellerCompany' //
    | 'eventShowPricesOfAllSellers' //
    | 'eventOpenCategoryProducts' //
    | 'eventOpenBrandProducts' //
    | 'eventOpenBrandAndCategoryProducts' //
    | 'eventOpenSizeChart' // +
    | 'eventOpenExternalLink' //
    | 'eventReceivePageAnalytics' //
    | 'eventSendMobileScreenName' //
    | 'eventOpenUmicoPremiumBenefits' //
    | 'eventUpdateToolbarHint' //

  const device = useDevice()

  // const analyticsStore = useAnalyticStore()
  // const { receiveAnaliticsData } = storeToRefs(analyticsStore)

  const pseudoCacheStore = usePseudoCacheStore()
  const { productSources } = storeToRefs(pseudoCacheStore)

  const jsonHelpers = useJsonHelpers()

  const isDevice = computed(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (device?.isIos && window.webkit) {
      return 'ios'
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (device?.isAndroid && AndroidJsBridge) {
      return 'android'
    }

    return ''
  })

  // ProductDetailsJavaScriptBridge

  const callDeviceEvent = (nameEvent: EventsDevice, params?: unknown) => {
    console.log(nameEvent, params)
    const body = JSON.stringify(params || '')

    if (isDevice.value === 'ios') {
      try {
        console.log({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent,
            body,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.[nameEvent]
          }
        })

        Sentry.addBreadcrumb({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent,
            body,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.[nameEvent]
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.webkit.messageHandlers[nameEvent].postMessage(body)
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('ios.event', nameEvent)
          Sentry.captureException(
            new Error(`IOSEventError ${nameEvent} ${error?.message}`)
          )
        })

        return Promise.reject(error)
      }
    }

    if (isDevice.value === 'android') {
      try {
        console.log({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent,
            body,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.[nameEvent]
          }
        })

        Sentry.addBreadcrumb({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent,
            body,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.[nameEvent]
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        AndroidJsBridge[nameEvent](body)
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('android.event', nameEvent)
          Sentry.captureException(
            new Error(`AndroidEventError ${nameEvent} ${error?.message}`)
          )
        })

        return Promise.reject(error)
      }
    }
  }

  // window.[nameEvent] ready
  const eventGlobalEventsReady = () => {
    callDeviceEvent('eventGlobalEventsReady')
  }

  const eventSendMobileScreenName = (
    toolbar_type: EventSendMobileScreenNameType,
    tab_layout_visibility: boolean,
    web_url: string,
    toolbar_hint?: string
  ) => {
    callDeviceEvent('eventSendMobileScreenName', {
      toolbar_type,
      tab_layout_visibility,
      web_url,
      toolbar_hint: toolbar_hint || null
    })
  }

  const eventUpdateToolbarHint = (toolbar_hint: string) => {
    callDeviceEvent('eventUpdateToolbarHint', {
      toolbar_hint
    })
  }

  // app loaded
  const eventPageLoaded = () => {
    console.log('eventPageLoaded')
    if (isDevice.value === 'android') {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        AndroidJsBridge.eventPageLoaded('')
      } catch (error) {
        console.error(error)
      }
    }
  }

  // cart
  const addProductDeviceCart = (
    productId: number,
    quantity: number = 1,
    offerId: string,
    productStatus: string
  ) => {
    callDeviceEvent('eventUpdateCart', {
      ...jsonHelpers.returnKeys('productId', productId),
      quantity,
      ...jsonHelpers.returnKeys('offerId', offerId),
      ...jsonHelpers.returnKeys('productStatus', productStatus)
    })
  }

  const deleteProductDeviceCart = (id: string, productId: number) => {
    if (isDevice.value === 'ios') {
      try {
        console.log({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventDeleteProductFromCart',
            body: {
              productId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.['eventDeleteProductFromCart']
          }
        })

        Sentry.addBreadcrumb({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventDeleteProductFromCart',
            body: {
              productId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.['eventDeleteProductFromCart']
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.webkit.messageHandlers.eventDeleteProductFromCart.postMessage(
          JSON.stringify({ productId })
        )
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('ios.event', 'eventDeleteProductFromCart')
          Sentry.captureException(
            new Error(
              `IOSEventError eventDeleteProductFromCart ${error?.message}`
            )
          )
        })

        return Promise.reject(error)
      }
    }

    if (isDevice.value === 'android') {
      try {
        console.log({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventDeleteProductFromCart',
            body: {
              id,
              productId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.['eventDeleteProductFromCart']
          }
        })

        Sentry.addBreadcrumb({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventDeleteProductFromCart',
            body: {
              id,
              productId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.['eventDeleteProductFromCart']
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        AndroidJsBridge.eventDeleteProductFromCart(
          JSON.stringify({ id, productId })
        )
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('android.event', 'eventDeleteProductFromCart')
          Sentry.captureException(
            new Error(
              `AndroidEventError eventDeleteProductFromCart ${error?.message}`
            )
          )
        })

        return Promise.reject(error)
      }
    }
  }

  const updateProductQuantity = (
    id: string,
    productId: number,
    quantity: number,
    productStatus: string
  ) => {
    if (isDevice.value === 'ios') {
      try {
        console.log({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventUpdateProductQuantity',
            body: {
              productId,
              quantity
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.['eventUpdateProductQuantity']
          }
        })

        Sentry.addBreadcrumb({
          category: 'ios_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventUpdateProductQuantity',
            body: {
              productId,
              quantity
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: window.webkit.messageHandlers?.['eventUpdateProductQuantity']
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.webkit.messageHandlers.eventUpdateProductQuantity.postMessage(
          JSON.stringify({ productId, quantity })
        )
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('ios.event', 'eventUpdateProductQuantity')
          Sentry.captureException(
            new Error(
              `IOSEventError eventUpdateProductQuantity ${error?.message}`
            )
          )
        })

        return Promise.reject(error)
      }
    }

    if (isDevice.value === 'android') {
      try {
        console.log({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventUpdateProductQuantity',
            body: {
              id,
              quantity,
              productId,
              productStatus
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.['eventUpdateProductQuantity']
          }
        })

        Sentry.addBreadcrumb({
          category: 'android_device_event',
          message: 'message',
          level: 'info',
          data: {
            nameEvent: 'eventUpdateProductQuantity',
            body: {
              id,
              quantity,
              productId,
              productStatus
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event: AndroidJsBridge?.['eventUpdateProductQuantity']
          }
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        AndroidJsBridge.eventUpdateProductQuantity(
          JSON.stringify({ id, quantity, productId, productStatus })
        )
      } catch (error: any) {
        Sentry.withScope(() => {
          Sentry.setTag('android.event', 'eventUpdateProductQuantity')
          Sentry.captureException(
            new Error(
              `AndroidEventError eventUpdateProductQuantity ${error?.message}`
            )
          )
        })

        return Promise.reject(error)
      }
    }
  }

  // favorites
  const addProductDeviceFavorites = (productID: number) => {
    callDeviceEvent('eventAddProductToFavourite', {
      ...jsonHelpers.returnKeys('productId', productID)
    })
  }

  const deleteProductDeviceFavorites = (productID: number) => {
    callDeviceEvent('eventDeleteProductFromFavorites', {
      ...jsonHelpers.returnKeys('productId', productID)
    })
  }

  // сomparisons
  const addProductComparisons = (productID: number, categoryID: string) => {
    callDeviceEvent('eventAddToComparisons', {
      ...jsonHelpers.returnKeys('productId', productID),
      ...jsonHelpers.returnKeys('categoryId', categoryID)
    })
  }

  const eventBuyOnCredit = (offerId: string) => {
    callDeviceEvent('eventBuyOnCredit', {
      offer_id: offerId
    })
  }

  // product
  const openProductImages = (imagesUrls: string[], currentIndex: number) => {
    callDeviceEvent('eventOpenProductImages', {
      ...jsonHelpers.returnKeys('listUrls', imagesUrls),
      ...jsonHelpers.returnKeys('currentIndex', currentIndex)
    })
  }

  // route

  // !!!!!!! ВАЖНО !!!!!!!
  // Перед навигацией на другой экран, нужно вызвать eventReceivePageAnalytics
  // он перезаписывает кеш у мобилок и мы получаем правильные данные для аналитики

  const eventReceivePageAnalytics = (data: IEventReceivePageAnalytics) => {
    // receiveAnaliticsData.value = data
    pseudoCacheStore.addToTargetArray(productSources?.value, data)
    // console.log('eventReceivePageAnalytics', receiveAnaliticsData.value)
    callDeviceEvent('eventReceivePageAnalytics', {
      position: data.position, // позиция в листинге
      item_list_name_source_type: data.itemListNameSourceType, // название листинга
      screen_before: data.screenBefore, // страница на которой был клик
      item_list_id_type: data.itemListIdType || null, // пока не юзаем
      analytics_search: data.analyticsSearch || null // пока не юзаем
    })
  }

  const toScreenCart = () => {
    callDeviceEvent('eventOpenCart')
  }

  const eventOpenVideoList = (videos: string[]) => {
    callDeviceEvent('eventOpenVideoList', {
      videos
    })
  }

  const toScreenCategory = (categoryID: string, categoryName: string) => {
    callDeviceEvent('eventOpenSearchByCategory', {
      ...jsonHelpers.returnKeys('categoryId', categoryID),
      ...jsonHelpers.returnKeys('categoryName', categoryName)
    })
  }

  const toScreenComparisons = (productID: number) => {
    callDeviceEvent('eventOpenComparisons', {
      ...jsonHelpers.returnKeys('productId', productID)
    })
  }

  const toReviews = (
    productId: number,
    rating: string,
    ratings_count: number,
    total: number
  ) => {
    callDeviceEvent('eventOpenReviews', {
      ...jsonHelpers.returnKeys('productId', productId),
      rating,
      ratings_count,
      total
    })
  }

  const toScreenProduct = (
    productID: number,
    analytics: IEventReceivePageAnalytics
  ) => {
    callDeviceEvent('eventOpenProductDetails', {
      ...jsonHelpers.returnKeys('productId', productID),
      analytics
    })
  }

  const toScreenCatalog = () => {
    callDeviceEvent('eventOpenCatalog')
  }

  const toScreenPremiumBenefits = () => {
    callDeviceEvent('eventOpenUmicoPremiumBenefits')
  }

  const toScreenAllMegaDiscounts = (categoryId: number, categoryName: string) => {
    callDeviceEvent('eventOpenAllMegaDiscounts', {
      category_id: String(categoryId),
      category_name: categoryName
    })
  }

  const toScreenAllSimilar = (categoryId: number, categoryName: string) => {
    callDeviceEvent('eventOpenAllSimilar', {
      category_id: String(categoryId),
      category_name: categoryName
    })
  }

  const openSellerCompany = (sellerName: string, sellerId: string) => {
    callDeviceEvent('eventOpenSellerCompany', {
      seller_name: sellerName,
      seller_id: sellerId
    })
  }

  const showPricesOfAllSellers = (productId: number) => {
    callDeviceEvent('eventShowPricesOfAllSellers', {
      product_id: productId
    })
  }

  const openCategoryProducts = (categoryId: number, categoryName: string) => {
    callDeviceEvent('eventOpenCategoryProducts', {
      category_id: `${categoryId}`,
      category_name: categoryName
    })
  }

  const openBrandProducts = (brand: string) => {
    callDeviceEvent('eventOpenBrandProducts', {
      brand
    })
  }

  const eventOpenBrandAndCategoryProducts = (
    categoryId: number,
    brand: string,
    name: string,
    isAdult: boolean
  ) => {
    callDeviceEvent('eventOpenBrandAndCategoryProducts', {
      category_id: `${categoryId}`,
      brand,
      product_category: {
        id: `${categoryId}`,
        name,
        is_adult: isAdult
      }
    })
  }

  const eventOpenExternalLink = (title: string, url: string) => {
    console.log('eventOpenExternalLink', title, url)
    callDeviceEvent('eventOpenExternalLink', {
      title,
      url
    })
  }

  const openSizeChart = (sizeChart: SizeChart) => {
    callDeviceEvent('eventOpenSizeChart', sizeChart)
  }

  const eventAnalytics = (eventKey: string, eventValue: any) => {
    deepRemoveNull(eventValue)

    const queryParams = {
      eventKey,
      eventValue: {
        ...eventValue,
        platforme_type: 'webview'
      }
    }

    Sentry.addBreadcrumb({
      category: 'analyticEvent',
      message: 'message',
      level: 'info',
      data: queryParams
    })
    try {
      callDeviceEvent('eventSendToAnalyticEvent', queryParams)
    } catch (error: any) {
      Sentry.withScope(() => {
        Sentry.setTag('analytic.event', eventKey)
        Sentry.captureException(
          new Error(`AnalyticEventError ${eventKey} ${error?.message}`)
        )
      })
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof dataLayer !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer.push(queryParams)
    }
  }
  const eventBloomrichAnalytics = (eventKey: string, eventValue: any) => {
    deepRemoveNull(eventValue)

    const queryParams = {
      eventKey,
      eventValue: {
        ...eventValue,
        platforme_type: 'webview'
      }
    }

    Sentry.addBreadcrumb({
      category: 'analyticBloomrichEvent',
      message: 'message',
      level: 'info',
      data: queryParams
    })
    try {
      callDeviceEvent('eventSendToBloomreachEvent', queryParams)
    } catch (error: any) {
      Sentry.withScope(() => {
        Sentry.setTag('analytic.event', eventKey)
        Sentry.captureException(
          new Error(`AnalyticEventError ${eventKey} ${error?.message}`)
        )
      })
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof dataLayer !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer.push(queryParams)
    }
  }

  return {
    eventGlobalEventsReady,
    eventPageLoaded,
    showPricesOfAllSellers,
    eventSendMobileScreenName,
    eventUpdateToolbarHint,

    addProductDeviceCart,
    deleteProductDeviceCart,
    updateProductQuantity,

    addProductDeviceFavorites,
    deleteProductDeviceFavorites,
    openSellerCompany,

    eventBuyOnCredit,

    addProductComparisons,

    openProductImages,
    openSizeChart,
    eventOpenVideoList,

    toScreenCart,
    toScreenCategory,
    toScreenComparisons,

    toScreenAllMegaDiscounts,
    toScreenAllSimilar,
    toScreenPremiumBenefits,

    toReviews,
    toScreenProduct,
    toScreenCatalog,
    openCategoryProducts,
    openBrandProducts,
    eventOpenBrandAndCategoryProducts,
    eventAnalytics,
    eventBloomrichAnalytics,
    eventOpenExternalLink,

    eventReceivePageAnalytics
  }
}
