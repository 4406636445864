<script setup lang="ts">
const props = defineProps({
  error: Object as () => NuxtError
})

const { error } = toRefs(props)
const router = useRouter()
const { eventViewItemABTest } = useAnalyticStore()

if (error.value && router.currentRoute.value.fullPath.includes('/product/')) {
  eventViewItemABTest({
    platform_type: 'webview',
    error_triggered: 1,
  })
}

console.error('error message', error?.value.message)

const handleError = () => {
  clearError()
  reloadNuxtApp()
}
</script>

<template>
  <div class="flex flex-col items-center text-[#1E244D] text-center px-4">
    <p class="mt-16 mb-[60px] text-[17px] leading-6 font-medium">
      <!-- {{ $t('error.alertSomethingWentWrongTitle') }} -->
    </p>
    <img
      src="./assets/images/error-page-img.png"
      alt=""
      width="220"
      height="220"
    />
    <p class="mt-10 text-[17px] leading-6 font-bold">
      {{ $t('error.webViewErrorMessageEmpty') }}
    </p>
    <p class="mt-4 text-[13px] leading-none font-normal">
      {{ $t('error.webViewErrorDescriptionEmpty') }}
    </p>

    <UIButton
      id="error-try-again"
      class="max-w-[342px] mt-6"
      color="primary"
      aria-label="error-try-again"
      @click="handleError"
    >
    <span
      id="error-try-again-text"
      :aria-label="$t('error.tryAgain')"
    >
      {{ $t('error.tryAgain') }}
    </span>
    </UIButton>
  </div>
</template>
