import VueLazyLoad from 'vue-lazyload'

export default defineNuxtPlugin({
  name: 'lazy-load',
  setup(nuxtApp) {
    nuxtApp.vueApp.use(VueLazyLoad, {
      observer: true,
      lazyComponent: true,
      observerOptions: {
        rootMargin: '0px',
        threshold: 0.1
      }
    })
  }
})
