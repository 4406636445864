export const useConfigStore = defineStore('config', () => {
  // state
  const isNavigationOnWeb = ref<boolean>(false) // включает навигацию на вебе (по умолчанию false)

  const isShowUseApiLogs = ref<boolean>(true) // включает логи для useApi (по умолчанию true)
  const isShowUseAuthLogs = ref<boolean>(true) // включает логи для useAuth (по умолчанию true)

  // getters
  const getIsNavigationOnWeb = computed((): boolean => {
    return isNavigationOnWeb.value
  })

  const getIsShowUseApiLogs = computed((): boolean => {
    return isShowUseApiLogs.value
  })

  const getIsShowUseAuthLogs = computed((): boolean => {
    return isShowUseAuthLogs.value
  })

  return {
    // getters
    getIsNavigationOnWeb,
    getIsShowUseApiLogs,
    getIsShowUseAuthLogs
  }
})
